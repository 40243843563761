<template>
  <section>
    <docs-list></docs-list>
  </section>
</template>

<script>
import DocsList from '../../components/Document/DocsList.vue';
export default {
  components: {
    DocsList,
  },
};
</script>
