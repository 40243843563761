<template>
  <div class="container mt-5">
    <a-row type="flex" justify="space-between" class="mb-3">
      <h4>Dokumentlar ro'yxati</h4>
      <router-link :to="{ name: 'newDocument' }">
        <a-button size="large" type="primary">
          <a-icon type="file-add" />
          Dokument qo'shish
        </a-button>
      </router-link>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :scroll="{ x: 500 }"
    >
      <template slot="name" slot-scope="text, record">
        <router-link
          class="text-dark font-weight-bold"
          :to="{
            name: 'editDocument',
            params: {
              docsId: record.id,
            },
          }"
          ><a-icon type="edit" /> {{ text }}
        </router-link>
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Sarlavha',
    dataIndex: 'title',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Sarlavha [en]',
    className: 'column-money',
    dataIndex: 'title_en',
  },
  {
    title: 'Sarlavha [ru]',
    dataIndex: 'title_ru',
  },
  {
    title: 'Sarlavha [uz]',
    dataIndex: 'title_uz',
  },
];
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      columns: columns,
      data: [],
    };
  },

  methods: {
    ...mapActions({
      getAllDocs: 'document/getAllDocument',
    }),
  },
  created() {
    this.getAllDocs().then(({ results }) => {
      this.data = results.map((item) => {
        return {
          id: item.id,
          key: item.id,
          title: item.title_ru,
          title_en: item.title_en,
          title_ru: item.title_ru,
          title_uz: item.title_uz,
        };
      });
    });
  },
};
</script>
